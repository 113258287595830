var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editLinks" },
    [
      _vm.formType === "project" && _vm.loginAgencyUserId
        ? _c(
            "div",
            { staticClass: "form-settings" },
            [
              _vm.loginUserIsFormCreater
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onEditProjectClick(
                              _vm.formDetail.projectId
                            )
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onCopyProjectClick(
                              _vm.formDetail.projectId
                            )
                          },
                        },
                      },
                      [_vm._v("复制")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.exportProjectTemplate(
                              _vm.formDetail.projectId
                            )
                          },
                        },
                      },
                      [_vm._v("导出模版")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onDeleteProjectClick(
                              _vm.formDetail.projectId
                            )
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                : _vm.formDetail && _vm.isInManagerId
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onEditProjectClick(
                              _vm.formDetail.projectId
                            )
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onCopyProjectClick(
                              _vm.formDetail.projectId
                            )
                          },
                        },
                      },
                      [_vm._v("复制")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.exportProjectTemplate(
                              _vm.formDetail.projectId
                            )
                          },
                        },
                      },
                      [_vm._v("导出模版")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.formType === "form"
        ? _c(
            "div",
            { staticClass: "form-settings" },
            [
              _vm.formDetail.userId === _vm.loginUserId
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onEditFormClick(_vm.formDetail)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onCopyFormClick(
                              _vm.formDetail.formId,
                              _vm.formDetail.isStop
                            )
                          },
                        },
                      },
                      [_vm._v("复制")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onMoveFormClick(
                              _vm.formDetail.formId,
                              _vm.formDetail.isStop
                            )
                          },
                        },
                      },
                      [_vm._v("移动")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-dropdown",
                      {
                        staticStyle: { float: "right" },
                        attrs: { "hide-on-click": false },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "el-dropdown-link" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "更多操作",
                                  placement: "top",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-more" })]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.exportFormTemplate(
                                      _vm.formDetail.formId
                                    )
                                  },
                                },
                              },
                              [_vm._v("导出模版")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.exportFormLogs(
                                      _vm.formDetail.formId
                                    )
                                  },
                                },
                              },
                              [_vm._v("导出日志")]
                            ),
                            _vm.formDetail.isStop
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onPauseFormClick(
                                          _vm.formDetail
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("暂停")]
                                )
                              : _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onStartFormClick(
                                          _vm.formDetail
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("启动")]
                                ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onDeleteFormClick(
                                      _vm.formDetail.formId,
                                      _vm.formDetail.isStop
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.formDetail &&
                  _vm.formDetail.managerUserId &&
                  _vm.isInManagerId
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onEditFormClick(_vm.formDetail)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.onCopyFormClick(
                              _vm.formDetail.formId,
                              _vm.formDetail.isStop
                            )
                          },
                        },
                      },
                      [_vm._v("复制")]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.exportFormTemplate(_vm.formDetail.formId)
                          },
                        },
                      },
                      [_vm._v("导出模版")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "移动表单",
            visible: _vm.formMoveVisible,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formMoveVisible = $event
            },
            close: _vm.onCloseMoveClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formMove",
              attrs: {
                model: _vm.formMoveObj,
                rules: _vm.formMoveRules,
                "label-width": "100px",
                "label-position": "right",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所移动应用", prop: "projectId" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择移动应用",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formMoveObj.projectId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formMoveObj, "projectId", $$v)
                            },
                            expression: "formMoveObj.projectId",
                          },
                        },
                        _vm._l(_vm.moveFormProjectList, function (item) {
                          return _c("el-option", {
                            key: item.projectId,
                            attrs: { label: item.name, value: item.projectId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmMoveClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelMoveClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }