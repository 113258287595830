<template>
  <div>
    <!-- el-dialog 强制绑定用户手机 -->
    <el-dialog title="" :visible.sync="bindOpen" width="450px" append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      custom-class="el-dialog-no-header"
      >
      <div class="VerificationDialogModalHeader">
        <h1 class="VerificationDialogModalHeader-title">手机号绑定</h1>
        <h2 class="VerificationDialogModalHeader-subTitle">为了保护你的帐号安全，请验证身份，验证成功后进行下一步操作</h2>
      </div>
      <el-form ref="bindForm" type="flex" :model="bindForm" :rules="bindFormRules" label-position="left" label-width="70px">
        <el-form-item prop="username" ref="username" :label="bindForm.type == 'phone' ? '手机号':'邮箱'">
          <el-input v-model="bindForm.username" type="text" auto-complete="off" :placeholder="bindForm.type == 'phone' ? '请输入绑定手机号' : '请输入绑定邮箱'"></el-input>
        </el-form-item>
        <el-form-item prop="code" ref="code" label="验证码">
          <el-input v-model="bindForm.code" auto-complete="off" placeholder="请输入验证码" style="width: calc(100% - 120px)"></el-input>
          <div class="fr send-code">
            <el-button plain size="medium" type="primary" style="width: 100px;padding: 10px 0;height: 32px;margin-top: 2px;line-height: 10px; box-sizing: border-box" v-show="isGetCode" @click.native.prevent="onCodeClick">获取验证码</el-button>
            <el-button plain size="medium" type="primary" style="width: 100px;padding: 10px 0;height: 32px;margin-top: 2px;line-height: 10px; box-sizing: border-box" v-show="!isGetCode" :disabled="disabled=!isGetCode">{{bindCount}}s后重试</el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="width: 50%; margin-top: -30px; margin-left: auto;margin-right: auto;">
        <el-button type="primary" style="width:100%;" @click.native.prevent="handleBind">确认绑定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
const TIME_COUNT = 60
import {getCodeImg, getPwdMobileCaptcha, resetPwd, sendBindingCode, updatePwd, sendChangeBindingCode, bindingPhone, checkChangeBindingCode} from "@/api/login";

import { getSessionStorageObj } from '@/utils/db'
export default {
  data () {
    return {
      bindOpen: false,
      bindForm: {
        code: '',
        type: 'phone', // phone  or  email
        username: ''
      },
      bindFormRules: {
        username	: [
          {
            required: true,
            validator: (rule, value, callback) => {
              let errorMsg = this.bindForm.type == 'phone' ? '手机号不能为空' : '邮箱不能为空'
              if (value == '' || !value) {
                callback(new Error(errorMsg))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        code: [
          { required: true, trigger: ["blur"], message: "验证码不能为空" }
        ]
      },
      isGetCode: true,
      bindCount: 0,
      bindTimer: null,
    }
  },
  created () {
    // 如果不强制绑定手机号，则不显示弹窗
    if(process.env.VUE_APP_BIND_MOBILE==='false'){
      return;
    }
    this.$store.dispatch("GetUserInfo").then((res) => {
      if (res && res.data && !res.data.mobile) {
        console.log('进入强制绑定手机号')
        this.bindOpen= true
      }
    });
  },
  methods:{
     // 点击获取验证码
    onCodeClick() {
      let _this = this
      if(_this.isBlank(_this.bindForm.username)) {
        let message = ''
        if (_this.bindForm.type == 'phone') {
          message = '请先输入手机号'
        } else {
          message = '请先输入邮箱'
        }
        _this.msgError(message);
        return;
      }
      let param = {
        type: _this.bindForm.type,
        username: _this.bindForm.username,
        source:1
      }
      sendBindingCode(param).then(res => {
        if (res.code === 200) {
          _this.bindCount = TIME_COUNT;
          _this.isGetCode = false;
          _this.bindTimer = setInterval(function(){
            if (_this.bindCount > 0 && _this.bindCount <= TIME_COUNT) {
              _this.bindCount--;
            } else {
              _this.isGetCode = true;
              clearInterval(_this.bindTimer);  // 清除定时器
              _this.bindTimer = null;
            }
          }, 1000)
          _this.msgSuccess("验证码发送成功");
        } else {
          _this.msgError(response.msg);
          _this.isGetCode = true;
          clearInterval(_this.bindTimer);
          _this.bindTimer = null;
        }
      });
    },
    // 确认绑定
    handleBind() {
      let _this = this
      _this.$refs.bindForm.validate(valid => {
        if (valid) {

        let currAgencyId = getSessionStorageObj('agencyId')
        let loginAgencyId = getSessionStorageObj('users').agencyIds.split(',')[0];
        let agencyId= Number( currAgencyId?currAgencyId:
                              loginAgencyId?loginAgencyId:'0');

          _this.bindForm.agencyId= agencyId;
          _this.bindForm.source  = "1";

          bindingPhone(_this.bindForm).then(res => {
            _this.$store.dispatch("UpdateUserMobile", _this.bindForm.username)
            let successMsg = _this.bindForm.type == 'phone' ? '绑定手机号成功' : '绑定邮箱成功'
            _this.$message({
              message: successMsg,
              type: "success"
            })
            _this.bindOpen = false
            _this.bindForm = {
              code: '',
              type: 'phone',
              username: ''
            }
            _this.$refs.bindForm.resetFields()
            _this.$refs['bindForm'].clearValidate()
            clearTimeout(_this.bindTimer)
            _this.bindTimer = null
          })
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
/*更换手机号、邮箱*/
.VerificationDialogModalHeader {
  width: 100%;
  text-align: center;
  .VerificationDialogModalHeader-title {
    margin-top: 0;
    font-size: 24px;
    color: #2b3441;
    margin-bottom: 10px;
    padding: 0;
  }
  .VerificationDialogModalHeader-subTitle {
    max-width: 240px;
    margin: 2px auto 24px;
    font-size: 14px;
    color: #8590a6;
    line-height: 22px;
  }
}
.VerificationDialogModal-oneSelection {
  font-size: 14px;
  text-align: left;
  color: #2b3441;
  font-weight: 500;
  margin-bottom: 16px;
}
</style>
