var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.bindOpen,
            width: "450px",
            "append-to-body": "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            "custom-class": "el-dialog-no-header",
          },
          on: {
            "update:visible": function ($event) {
              _vm.bindOpen = $event
            },
          },
        },
        [
          _c("div", { staticClass: "VerificationDialogModalHeader" }, [
            _c("h1", { staticClass: "VerificationDialogModalHeader-title" }, [
              _vm._v("手机号绑定"),
            ]),
            _c(
              "h2",
              { staticClass: "VerificationDialogModalHeader-subTitle" },
              [
                _vm._v(
                  "为了保护你的帐号安全，请验证身份，验证成功后进行下一步操作"
                ),
              ]
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "bindForm",
              attrs: {
                type: "flex",
                model: _vm.bindForm,
                rules: _vm.bindFormRules,
                "label-position": "left",
                "label-width": "70px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "username",
                  attrs: {
                    prop: "username",
                    label: _vm.bindForm.type == "phone" ? "手机号" : "邮箱",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "auto-complete": "off",
                      placeholder:
                        _vm.bindForm.type == "phone"
                          ? "请输入绑定手机号"
                          : "请输入绑定邮箱",
                    },
                    model: {
                      value: _vm.bindForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.bindForm, "username", $$v)
                      },
                      expression: "bindForm.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { ref: "code", attrs: { prop: "code", label: "验证码" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "calc(100% - 120px)" },
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "请输入验证码",
                    },
                    model: {
                      value: _vm.bindForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.bindForm, "code", $$v)
                      },
                      expression: "bindForm.code",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "fr send-code" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isGetCode,
                              expression: "isGetCode",
                            },
                          ],
                          staticStyle: {
                            width: "100px",
                            padding: "10px 0",
                            height: "32px",
                            "margin-top": "2px",
                            "line-height": "10px",
                            "box-sizing": "border-box",
                          },
                          attrs: { plain: "", size: "medium", type: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onCodeClick($event)
                            },
                          },
                        },
                        [_vm._v("获取验证码")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isGetCode,
                              expression: "!isGetCode",
                            },
                          ],
                          staticStyle: {
                            width: "100px",
                            padding: "10px 0",
                            height: "32px",
                            "margin-top": "2px",
                            "line-height": "10px",
                            "box-sizing": "border-box",
                          },
                          attrs: {
                            plain: "",
                            size: "medium",
                            type: "primary",
                            disabled: (_vm.disabled = !_vm.isGetCode),
                          },
                        },
                        [_vm._v(_vm._s(_vm.bindCount) + "s后重试")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                width: "50%",
                "margin-top": "-30px",
                "margin-left": "auto",
                "margin-right": "auto",
              },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleBind($event)
                    },
                  },
                },
                [_vm._v("确认绑定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }