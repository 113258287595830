<template>
  <div class="editLinks">
    <div v-if="formType === 'project' && loginAgencyUserId" class="form-settings">
      <template v-if="loginUserIsFormCreater">
        <el-button type="text" size="mini" @click="onEditProjectClick(formDetail.projectId)">修改</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" size="mini" @click="onCopyProjectClick(formDetail.projectId)">复制</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" size="mini" @click="exportProjectTemplate(formDetail.projectId)">导出模版</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" size="mini" @click="onDeleteProjectClick(formDetail.projectId)">删除</el-button>
      </template>
      <template v-else-if="formDetail && isInManagerId">
        <el-button type="text" size="mini" @click="onEditProjectClick(formDetail.projectId)">修改</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" size="mini" @click="onCopyProjectClick(formDetail.projectId)">复制</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" size="mini" @click="exportProjectTemplate(formDetail.projectId)">导出模版</el-button>
      </template>
    </div>
    <div v-if="formType === 'form'" class="form-settings">
      <template v-if="formDetail.userId === loginUserId">
        <el-button type="text" size="mini" @click="onEditFormClick(formDetail)">修改</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" size="mini" @click="onCopyFormClick(formDetail.formId,formDetail.isStop)">复制</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" size="mini" @click="onMoveFormClick(formDetail.formId,formDetail.isStop)">移动</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-dropdown :hide-on-click="false" style="float: right;">
          <span class="el-dropdown-link">
            <el-tooltip class="item" effect="dark" content="更多操作" placement="top">
              <i class="el-icon-more"></i>
            </el-tooltip>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="exportFormTemplate(formDetail.formId)">导出模版</el-dropdown-item>
            <el-dropdown-item @click.native="exportFormLogs(formDetail.formId)">导出日志</el-dropdown-item>
            <el-dropdown-item v-if="formDetail.isStop" @click.native="onPauseFormClick(formDetail)">暂停</el-dropdown-item>
            <el-dropdown-item v-else @click.native="onStartFormClick(formDetail)">启动</el-dropdown-item>
            <el-dropdown-item @click.native="onDeleteFormClick(formDetail.formId,formDetail.isStop)">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template v-else-if="formDetail && formDetail.managerUserId && isInManagerId">
        <el-button type="text" size="mini" @click="onEditFormClick(formDetail)">修改</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" size="mini" @click="onCopyFormClick(formDetail.formId,formDetail.isStop)">复制</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="text" size="mini" @click.native="exportFormTemplate(formDetail.formId)">导出模版</el-button>
      </template>
    </div>
    <el-dialog title="移动表单" :visible.sync="formMoveVisible" width="600px" append-to-body @close="onCloseMoveClick">
      <el-form ref="formMove" :model="formMoveObj" :rules="formMoveRules" label-width="100px" label-position="right" @submit.native.prevent>
        <el-form-item label="所移动应用" prop="projectId">
          <el-col :span="24">
            <el-select v-model="formMoveObj.projectId" placeholder="请选择移动应用" filterable style="width: 100%">
              <el-option v-for="item in moveFormProjectList" :key="item.projectId" :label="item.name" :value="item.projectId" />
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmMoveClick">确 定</el-button>
        <el-button @click="onCancelMoveClick">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    setFormIsStop,
    copyProject,
    dismissProject,
    projectSelectList,
    moveForm,
    copyForm,
    deleteProject,
    deleteForm,
    exportFormTemplate,
    exportFormLogs
  } from "@/api/system/form";
  import axios from "axios";
  export default {
    name: "editLink",
    props: {
      formType: {
        type: String,
        default: "form",
      },
      formDetail: {
        type: Object,
      },
      returnType: {
        type: String,
        default: "refresh",
      },
      loginUserId: {
        type: Number,
        default: 0,
      },
      loginAgencyUserId: {
        type: String | Array,
        // default:""
      },
    },
    data() {
      return {
        newProjectOpen: false,
        formMoveObj: {},
        moveFormProjectList: [],
        formMoveRules: {},
        formMoveVisible: false,
      };
    },
    computed: {
      isInManagerId: function () {
        // loginAgencyUserId 是数组，formDetail.managerUserId 也是数组，判断loginAgencyUserId是否在formDetail.maanagerUserId中
        // 270703 发现loginAgencyUserId 是数组，之前按照字符串判断是错误的
        if(this.loginAgencyUserId && this.formDetail){
          if(Array.isArray(this.loginAgencyUserId)){
            return (this.formDetail.managerUserId||'').split(',').some(x=>this.loginAgencyUserId.includes(x))
          }else{
            return (this.formDetail.managerUserId||'').split(',').indexOf(this.loginAgencyUserId) != -1
          }
        }else{
          return false;
        }
        // for (let i = 0; i < this.loginAgencyUserId.length; i++) {
        //   if (
        //     this.formDetail.managerUserId &&
        //     this.formDetail.managerUserId.indexOf(this.loginAgencyUserId[i]) != -1
        //   ) {
        //     return true;
        //   }
        // }
      },
      loginUserIsFormCreater:function(){
        return this.formDetail.userId === this.loginAgencyUserId || this.formDetail.userId === this.loginUserId
      }
    },
    methods: {
      // 修改应用
      onEditProjectClick(id) {
        this.$emit("editProject", id);
      },
      // 复制应用
      onCopyProjectClick(id) {
        let _this = this;
        _this
          .$confirm("确认复制该应用？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(function () {
            return copyProject(id);
          })
          .then(() => {
            _this.$message({
              type: "success",
              message: "复制成功",
            });
            _this.$emit("refreshList");
          })
          .catch(function () {});
      },
      // 解散应用
      onDismissProjectClick(id) {
        let _this = this;
        _this
          .$confirm("确认删除该应用？", "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(function () {
            return dismissProject(id);
          })
          .then(() => {
            _this.$message({
              message: "删除应用成功",
              type: "success",
            });
            _this.$emit("refreshList");
          })
          .catch(function () {});
      },
      // 删除应用
      onDeleteProjectClick(id) {
        let _this = this;
        _this
          .$confirm("确认删除该应用？", "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(function () {
            return deleteProject(id);
          })
          .then(() => {
            _this.$message({
              message: "删除应用成功",
              type: "success",
            });
            _this.$emit("refreshList");
          })
          .catch(function () {});
      },
      // 修改表单
      onEditFormClick(item) {
        // isStop true 为可使用，isStop false 是停用
        // if (!item.isStop) {
        //   return
        // }
        if (item.projectId != 0) {
          this.$router.push({
            path: "/form/edit/" + this.encode(item.formId),
            query: {
              name: this.encode(item.name),
              projectId: this.encode(item.projectId),
            },
          });
        } else {
          this.$router.push({
            path: "/form/edit/" + this.encode(item.formId),
            query: {
              name: this.encode(item.name),
              projectId: 0,
            },
          });
        }
      },
      onCopyFormClick(id, isStop) {
        // isStop true 为可使用，isStop false 是停用
        // if (!isStop) return
        const _this = this;
        _this
          .$confirm("确认复制该表单？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(function () {
            return copyForm(id);
          })
          .then(() => {
            _this.$message({
              type: "success",
              message: "复制成功",
            });
            _this.$emit("refreshList");
          })
          .catch(function () {});
      },
      // 移动表单
      onMoveFormClick(id, isStop) {
        // isStop true 为可使用，isStop false 是停用
        // if (!isStop) return
        this.clearMoveFormData();
        this.moveFormId = id;
        this.formMoveVisible = true;

        projectSelectList().then((response) => {
          if (response.code == 200) {
            this.moveFormProjectList = response.data;
          }
        });
      },
      onCloseMoveClick() {
        this.clearMoveFormData();
      },
      clearMoveFormData() {
        if (this.$refs["formMove"]) {
          this.$refs["formMove"].clearValidate();
        }
        this.moveFormId = null;
        this.formMoveObj = {
          projectId: null,
        };
        this.moveFormProjectList = [];
      },
      onConfirmMoveClick() {
        const _this = this;
        this.$refs["formMove"].validate((valid) => {
          if (valid) {
            const param = {
              formId: _this.moveFormId,
              projectId: _this.formMoveObj.projectId,
            };
            moveForm(param).then((response) => {
              if (response.code == 200) {
                _this.msgSuccess("移动成功");
                _this.formMoveVisible = false;
                _this.clearMoveFormData();
                // if (_this.formType == "form" && _this.$route.path != "/index") {
                //   _this.$router.back();
                // } else {
                  _this.$emit("refreshList");
                // }
              }
            });
          }
        });
      },
      onCancelMoveClick() {
        this.formMoveVisible = false;
        this.clearMoveFormData();
      },
      // 删除表单
      onDeleteFormClick(id, isStop) {
        // isStop true 为可使用，isStop false 是停用
        // if (!isStop) return

        const _this = this;
        _this
          .$confirm("确认删除该表单？", "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(async function () {
            deleteForm(id).then(() => {
              _this.$message({
                message: "删除表单成功",
                type: "success",
              });
              if (_this.$route.name == "multipleFormDetail") {
                _this.$emit("home");
                return;
              }
              if (_this.returnType == "back") {
                _this.$router.back();
              } else {
                _this.$emit("refreshList");
              }
            });
          })
          .catch(function () {});
      },
      onPauseFormClick(id) {
        this.$confirm("表单暂停后将不能进行任何操作，确认要暂停吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          distinguishCancelAndClose: true, // 区分取消与关闭
        }).then(async () => {
          let res = await setFormIsStop(this.formDetail.formId, 0);
          if (res.code == 200) {
            this.formDetail.isStop = false;
            this.$forceUpdate();
            // this.$emit('formStopUpdate', {
            //   formId:this.formDetail.formId,
            //   isStop:false
            // })
          }
        });
      },
      async onStartFormClick(id) {
        let res = await setFormIsStop(this.formDetail.formId, 1);
        if (res.code == 200) {
          this.formDetail.isStop = true;
          this.$forceUpdate();
          // this.$emit('formStopUpdate', {
          //   formId:this.formDetail.formId,
          //   isStop:true
          // })
        }
      },
      exportFormTemplate(formId) {
        const aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = "/api/form/exportFormJson/" + formId;
        aLink.click();
      },
      exportFormLogs(formId) {
         exportFormLogs(formId)
          .then(
            (data) => {
              if (!data) {
                return false
              }
              const blob = new Blob([data])
              const url = URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url
              link.download = `表单填报日志.xlsx`
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            },
            (error) => {
              this.$message.error('下载异常，请稍后再试')
            }
          )
      },
      exportProjectTemplate(projectId) {
        const aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = "/api/project/exportProjectJson/" + projectId;
        aLink.click();
      },
    },
  };
</script>
<style lang="scss" scope="scoped">
  .form-settings {
    font-size: 12px;
    text-align: right;
    .split-button {
      cursor: pointer;
      padding: 0 15px;
      border-right: 1px solid #dbdde7;
      color: #3476f0;
      svg {
        margin-right: 4px;
      }
      img {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 5px;
        vertical-align: middle;
      }
      &:last-child {
        border-right: 0;
        padding-right: 0;
      }
      &.disabled {
        color: #aaaaaa !important;
        img {
          filter: grayscale(100%);
        }
      }
    }
  }

  .editLinks {
    .el-dialog__header {
      text-align: left;
      padding: 20px 20px 10px;
    }
    .el-dialog__title {
      line-height: 24px;
      font-size: 18px;
      color: #303133;
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #3476f0;
  }
</style>
